<template>
  <el-dialog
    title="Edit User"
    :visible.sync="dialogVisible"
    :width="isMobile ? '95%' : '50%'"
    v-loading="loading"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :before-close="beforeClose"
  >
    <el-form
      ref="editUserForm"
      :model="userForm"
      :rules="userFormRules"
      label-width="120px"
    >
      <el-form-item label="Name" prop="name">
        <el-input
          v-model="userForm.name"
          class="form-control-alternative"
        ></el-input>
      </el-form-item>
      <el-form-item label="Username" prop="username">
        <el-input
          v-model="userForm.username"
          class="form-control-alternative"
        ></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input
          v-model="userForm.email"
          class="form-control-alternative"
        ></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input
          type="password"
          show-password
          v-model="userForm.password"
          class="form-control-alternative"
        >
          <el-tooltip
            slot="append"
            effect="dark"
            content="Generate Password"
            placement="top"
          >
            <el-button icon="el-icon-magic-stick" @click="generatePassword()"></el-button>
          </el-tooltip>
        </el-input>
      </el-form-item>
      <el-form-item label="Role" prop="role">
        <el-select
          v-model="userForm.role"
          placeholder="Select role"
          class="form-control-alternative"
        >
          <el-option
            v-for="item in roles"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="submitForm()" v-loading="submiting"
      :disabled="submiting"
        >Save</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { isMobile, generatePassword } from "@/utils/helpers";
import ManageApi from "@/api/manage";
export default {
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      dialogVisible: false,
      roles: [
        {
          value: "user",
          label: "User",
        },
        {
          value: "admin",
          label: "Admin",
        },
        {
          value: "paymaker",
          label: "Paymaker",
        },
        {
          value: "accounting",
          label: "Accounting",
        },
        {
          value: "treasury",
          label: "Treasury",
        },
      ],
      userUID: "",
      userForm: {
        name: "",
        email: "",
        username: "",
        password: "",
        role: "",
      },
      userFormRules: {
        name: [
          {
            required: true,
            message: "Please input name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input valid email",
            trigger: "blur",
          },
        ],

        username: [
          {
            required: true,
            message: "Please input username",
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message: "Please select role",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    show(uid) {
      this.resetForm();
      this.userUID = uid;
      this.loading = true;
      ManageApi.getSubuser(uid).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.userForm.name = data.name;
          this.userForm.username = data.username;
          this.userForm.email = data.email;
          this.userForm.role = data.role;
          this.dialogVisible = true;
        }
      });
    },
    hide() {
      this.dialogVisible = false;
    },
    resetForm() {
      this.userForm.name = "";
      this.userForm.email = "";
      this.userForm.username = "";
      this.userForm.password = "";
      this.userForm.role = "";
    },
    submitForm() {
      this.$refs.editUserForm.validate((valid) => {
        if (valid) {
          this.submitData();
        }
      });
    },
    submitData() {
      this.submiting = true;
      ManageApi.putSubuser(this.userUID, this.userForm).then(({ result, message }) => {
        this.submiting = false;
        if (result) {
          this.$swal(`Message`, `Update User is success`, "success").then(
            () => {
              this.dialogVisible = false;
              this.submiting = false;
              this.loading = false;
              this.$emit("submited");
            }
          );
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    generatePassword(){
      this.userForm.password = generatePassword();
  
    },
    beforeClose() {
      this.$refs.editUserForm.resetFields();
      this.dialogVisible = false;
      this.submiting = false;
      this.loading = false;
    },
  },
};
</script>

<style>
</style>