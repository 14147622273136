<template>
  <div>
    <div class="container-fluid mt--6">

      <div class="row">
        <div class="col-lg-12">
          <div class="card mb-3">
            <div class="card-header">
              Users of {{ mainUser.name }} ({{mainUser.username}})
            </div>

            <div class="card-body min-vh">
              <div class="text-right mb-4">
                  <button type="button" class="btn btn-primary" @click="addUser">Add User</button>
              </div>
              <el-table
                :data="tableData"
                border
                :loading="loading"
                :disabled="loading"
                style="width: 100%"
              >
  
                <el-table-column
                  prop="name"
                  label="Name"
                  header-align="center"
                ></el-table-column>
                <el-table-column
                  prop="username"
                  label="User Name"
                  header-align="center"
                  width="220"
                ></el-table-column>
                <el-table-column
                  prop="email"
                  label="Email"
                  header-align="center"
                  width="320"
                ></el-table-column>
                <el-table-column
                  prop="role"
                  label="Role"
                  header-align="center"
                  align="center"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  prop="userid"
                  label="Action"
                  header-align="center"
                  align="center"
                  width="250"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="editUser(scope.row.uid)"
                      >Edit</el-button
                    >
                    <el-button
                      type="info"
                      class="btn-mini"
                      @click="resetPin(scope.row)"
                      >Reset PIN</el-button
                    >

                    <el-button
                      type="info"
                      class="btn-mini"
                      @click="resetPassword(scope.row.uid)"
                      >Reset Password</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-user ref="adduser" v-on:submited="loadUser"/>
    <edit-user ref="edituser" v-on:submited="loadUser"/>

        <el-dialog
      title="Send reset password to customer"
      :visible.sync="dialogPasswordReset"
      width="50%"
    >
      <el-form
        label-width="100px"
        ref="formResetPassword"
        :model="formResetPassword"
      >
        <el-form-item label="Name">
          <el-input
            v-model="formResetPassword.name"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="User Name" prop="username">
          <el-input
            v-model="formResetPassword.username"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input
            v-model="formResetPassword.email"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPasswordReset = false">Cancel</el-button>
        <el-button
          type="primary"
          v-loading="submitingResetPassword"
          @click="sendResetPassword()"
          >Submit</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ManageApi from "@/api/manage";
import AddUser from "./components/Add";
import EditUser from "./components/Edit";
import {forgotPassword} from '@/api/user'
export default {
  components: {
    AddUser,
    EditUser,
  },
  data() {
    return {
      loading: false,
      mainUser: {},
      tableData: [],
      dialogPasswordReset: false,
      submitingResetPassword: false,
      formResetPassword: {
        id: "",
        name: "",
        username: "",
        email: "",
      },
    };
  },
  methods:{
    loadUser() {
      console.log("loadUser");
      this.getSubuserLists(this.$route.params.plusuid);
    },
    getUser(id) {
      const loading = this.$notifyloading("Loading...");
      ManageApi.getUser(id).then(({ result, data }) => {
        loading.close();
        if (result) {
          this.mainUser = data;
        }
      });
    },
    addUser() {
      this.$refs.adduser.show();
    },
    editUser(uid) {
      this.$refs.edituser.show(uid);
    },
    getSubuserLists(plusuid){
      this.loading = true
      ManageApi.getSubuserLists(plusuid).then(({ result, data }) => {
        this.loading = false
        if (result) {
          this.tableData = data;
        }
      });
    },
    resetPassword(uid) {
      const loading = this.$notifyloading("Loading...");
      ManageApi.getSubuser(uid).then(({ result, data }) => {
        loading.close();
        if (result) {
          this.formResetPassword.id = data.id;
          this.formResetPassword.name = data.name;
          this.formResetPassword.username = data.username;
          this.formResetPassword.email = data.email;
          this.dialogPasswordReset = true;
        }
      });
    },
    sendResetPassword() {
      this.submitingResetPassword = true;
      const formData = {
        email: this.formResetPassword.email,
        forgot_user: true,
        forgot_password: true,
      };
      forgotPassword(formData).then(
        ({ result, message }) => {
          this.dialogPasswordReset = false;
          this.submitingResetPassword = false;

          if (result) {
            this.$refs.formResetPassword.resetFields();
            this.$swal(`Message`, message, "success");
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    resetPin(data){
      this.$swal({
          title: 'Reset Passcode',
          html: `This will reset passcode in application of <b>${data.name}</b>. Continue?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Comfirm",
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            ManageApi.postSubuserResetPin(data.uid, {resetpin: 1}).then(({ result, message }) => {
              if (result) {
                  this.$swal(`Message`, message, "success");
                } else {
                  this.$swal("Message", message, "error");
                }
            })
           } else {
            this.$message(`Remove payee ${data.name} Canceled`); 
          }
        });
    },
  },
  mounted(){
    this.getUser(this.$route.params.id);
    this.getSubuserLists(this.$route.params.plusuid);
  }
}
</script>

<style>

</style>